/* Fonts */

@font-face {
  font-family: "NeurialGrotesk-Private";
  font-style: normal;
  font-weight: 300;
  src: url("/public/fonts/neurial-grotesk/NeurialGrotesk-Light.otf");
}

@font-face {
  font-family: "NeurialGrotesk-Private";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/neurial-grotesk/NeurialGrotesk-Regular.otf");
}

@font-face {
  font-family: "NeurialGrotesk-Private";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/neurial-grotesk/NeurialGrotesk-Medium.otf");
}

@font-face {
  font-family: "NeurialGrotesk-Private";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/neurial-grotesk/NeurialGrotesk-Bold.otf");
}

@font-face {
  font-family: "NeurialGrotesk-Private";
  font-style: normal;
  font-weight: 700;
  src: url("/public/fonts/neurial-grotesk/NeurialGrotesk-Extrabold.otf");
}

@font-face {
  font-family: "Digital-Geometric-Private";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/Digital-Geometric/Digital-Geometric.ttf");
}

:root {
  --transition: cubic-bezier(0.86, 0, 0.07, 1);
}

* {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  max-width: 2560px;
  margin: 0 auto;
  background: #292D32;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(162, 162, 167);
  border-radius: 100px;
}

/* Animations */

.anim-illus {
  visibility: visible;
  opacity: 1;
  transition: all ease 0.5s;
  /* animation: slowZoom 30s ease; */
}

.anim-illus-hidden.anim-scale-down {
  transform: scale(1.5);
  visibility: hidden;
}

.anim-illus-hidden.anim-scale-up {
  transform: scale(0.5);
  visibility: hidden;
}

.visible {
  opacity: 1;
  transition: all ease 0.3s;
  transform: scale(1);
}

.invisible {
  position: absolute;
  opacity: 0;
  transform: scale(1.5);
}

[data-sal|="fade-in"] {
  opacity: 0;
  transition: opacity 0.6s ease;
}

[data-sal|="fade-in"].sal-animate {
  opacity: 1;
}

[data-sal|="fade-up"] {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-up"].sal-animate {
  opacity: 1;
  transform: translateY(0);
}

[data-sal|="fade-left"] {
  opacity: 0;
  transform: translateX(40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-left"].sal-animate {
  opacity: 1;
  transform: translateX(0);
}

[data-sal|="fade-right"] {
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-right"].sal-animate {
  opacity: 1;
  transform: translateX(0);
}

[data-sal|="fade-up-left"] {
  opacity: 0;
  transform: translate(40px, 40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-up-left"].sal-animate {
  opacity: 1;
  transform: translate(0px, 0px);
}

[data-sal|="fade-up-right"] {
  opacity: 0;
  transform: translate(-40px, 40px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

[data-sal|="fade-up-right"].sal-animate {
  opacity: 1;
  transform: translate(0px, 0px);
}